<template>
  <div>
    <b-navbar class="nav">
      <div class="col-sm"></div>
      <router-link to="/" tag="h1">LINKTO NINJA</router-link>
      <div class="col-sm"></div>
    </b-navbar>
    <sign-in
      v-if="!ifShowRegistration"
      @register="viewRegistration"
    ></sign-in>
    <sign-up
      v-else
      @register="viewRegistration"
    ></sign-up>
  </div>
</template>

<script>
import SignUp from '../components/SignUp.vue';
import SignIn from '../components/SignIn.vue';

export default {
  name: 'Login',
  data() {
    return {
      ifShowRegistration: false,
    };
  },
  components: {
    SignUp,
    SignIn,
  },
  methods: {
    viewRegistration() {
      this.ifShowRegistration = !this.ifShowRegistration;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/master";

.nav {
  padding: 30px;
  h1{
    font-size: 60px;
    text-shadow: 6px 6px $accent-color;
    &:hover{
      cursor: pointer;
    }
  }
}
</style>
