<template>
  <div class="shorted_url">
    <alert ref="alert"></alert>
    <b-input-group class="inputs mt-3">
      <b-form-input
        id="input-url"
        :state="$v.urlValue.$dirty ? !$v.urlValue.$error : null"
        v-model="$v.urlValue.$model"
        placeholder="paste link to be shorten"
        required
      ></b-form-input>
      <b-input-group-append>
        <b-button
          @click="isButtonDisabled ? alertError() : $emit('shorten-url', urlValue)"
          class="shorten-url-button"
          :class="{'button-disabled': isButtonDisabled}"
          style="border-radius: 0 0.3rem 0.3rem 0"
        >Shorten!</b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators';
import Alert from './common/Alert.vue';

export default {
  name: 'ShortUrl',
  data: () => ({
    urlValue: '',
    alertMessage: '',
    dismissSecs: 0,
  }),
  computed: {
    isButtonDisabled() {
      return this.$v.urlValue.$error || !this.$v.urlValue.$dirty;
    },
  },
  components: {
    Alert,
  },
  validations: {
    urlValue: {
      required,
      url,
    },
  },
  methods: {
    alertError() {
      if (this.urlValue) {
        this.dismissSecs = 5;
        this.alertMessage = 'Make sure your url starts with protocol name ( usually http:// )';
      } else {
        this.dismissSecs = 3;
        this.alertMessage = 'Please enter url to be shorten';
      }
      this.$refs.alert.showAlert(this.alertMessage, this.dismissSecs);
    },
  },
};
</script>

<style lang="scss">
  @import "../assets/master";

  .inputs{
    width: 90%;
    max-width: 600px;
    margin: 0 auto 60px;
    .form-control, .shorten-url-button {
      height: 3.4rem;
      padding: 1.1rem 1.4rem;
      font-size: 1.2rem;
      line-height: 1.2rem;
      border-radius: 0.3rem;
      border: 1px solid $main-color;
    }
    .form-control {
      &:focus {
        box-shadow: inset 0.2em 0.2em $accent-color, inset -0.2em -0.2em $accent-color;
        border: 1px solid $main-color;
      }
    }
  }
  .shorten-url-button{
    background-color: $main-color;
    color: white;
    transition-duration: 150ms;
    &:focus{
      box-shadow: None;
      background-color: $main-color;
    }
    &:hover{
      background-color: $accent-color;
      color: $main-color;
    }
    &:active{
      box-shadow: None !important;
      background-color: $main-color !important;
    }
  }
  .button-disabled:hover{
    background-color: #ff5555;
    cursor: not-allowed !important;
  }
</style>
