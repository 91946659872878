<template>
  <div>
    <b-navbar class="nav">
      <div class="col-sm text-left">
        <router-link to="/" tag="h1">LINKTO NINJA</router-link>
      </div>
      <div class="col-sm text-right">
        <navigation-button
          val="Home"
          @click.native="$router.push({name: 'Home'})"
        ></navigation-button>
        <navigation-button
          val="Analytics"
          @click.native="$router.push({name: 'Stats'})"
        ></navigation-button>
        <navigation-button
          val="Log Out"
          @click.native="logout"
        ></navigation-button>
      </div>
    </b-navbar>
    <div class="main">
      <div class="table-list">
        <h2 @click="changeCurrentTable('user')">Users</h2>
        <h2 @click="changeCurrentTable('url')">Shortened Urls</h2>
        <h2 @click="changeCurrentTable('log')">Logs</h2>
      </div>
      <div class="table-content">
        <log-table
          v-if="currentTable==='log'"
          :ready="false"
        ></log-table>
        <user-table
          v-else-if="currentTable==='user'"
          :ready="false"
        ></user-table>
        <url-table
          v-else-if="currentTable==='url'"
          :ready="false"
        ></url-table>
        <h3 v-else>Please select table</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { userDataLSService } from '../localStorage';
import LogTable from '../components/admin/LogTable.vue';
import UserTable from '../components/admin/UserTable.vue';
import UrlTable from '../components/admin/UrlTable.vue';
import NavigationButton from '../components/common/NavigationButton.vue';

export default {

  name: 'Admin',
  data() {
    return {
      currentTable: '',
    };
  },
  components: {
    LogTable,
    UserTable,
    UrlTable,
    NavigationButton,
  },
  methods: {
    async changeCurrentTable(newTable) {
      this.currentTable = newTable;
    },
    logout() {
      userDataLSService.remove();
      this.$router.go();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/master";

.main {
  margin-top: 60px;
  .table-list {
    display: flex;
    flex-wrap: wrap;
  };
  .table-content {
    margin: 1rem;
    padding: 30px 5px;
    border: 1px solid $main-color;
    border-radius: 0.3rem;
  }
  h2{
    margin: 1rem;
    padding: 10px;
    color: $main-color;
    font-size: 30px;
    border: 1px solid $main-color;
    border-radius: 0.3rem;
    transition-duration: 300ms;
    &:hover{
      background-color: $accent-color;
      cursor: pointer;
    }
  }
  h3 {
    margin: 20px;
  }
}
.nav {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  h1{
    font-size: 60px;
    text-shadow: 6px 6px $accent-color;
    &:hover{
      cursor: pointer;
    }
  }
}
</style>
