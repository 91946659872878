<template>
  <div>
    <div>
      <b-modal
        id="modal-prevent-closing"
        ref="modal"
        @ok="handleOk"
      >
        <form ref="form">
          <b-form-group
            label="E-mail"
            label-for="email-input"
          >
            <b-form-input
              id="email-input"
              v-model="email"
              placeholder="Current value is default"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Role Id"
            label-for="role-id-input"
          >
            <b-form-input
              id="role-id-input"
              v-model="roleId"
              placeholder="Current value is default"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Plan Id"
            label-for="plan-id-input"
          >
            <b-form-input
              id="plan-id-input"
              v-model="planId"
              placeholder="Current value is default"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Password"
            label-for="password-input"
          >
            <b-form-input
              id="password-input"
              v-model="password"
              placeholder="Current value is default"
            ></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </div>

    <loader v-if="loadingData" class="loader"></loader>
    <div v-else>
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Type to Search"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filter On"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            description="Leave all unchecked to filter on ALL data"
            class="mb-0">
            <b-form-checkbox-group v-model="filterOn" class="mt-1">
              <b-form-checkbox value="email">Email</b-form-checkbox>
              <b-form-checkbox value="token">Token</b-form-checkbox>
              <b-form-checkbox value="plan_id">Plan Id</b-form-checkbox>
              <b-form-checkbox value="role_id">Role Id</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Per page"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
      <!-- Main table element -->
      <b-table
        show-empty
        stacked="md"
        responsive="true"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filterIncludedFields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >
        <template v-slot:cell(name)="row">
          {{ row.value.first }} {{ row.value.last }}
        </template>
        <template v-slot:cell(actions)="row">
          <b-button
            class="action-button"
            size="sm"
            @click="updateRow(row.item.token)"
            v-b-modal.modal-prevent-closing
          >Edit</b-button>
          <b-button class="action-button" size="sm" @click="deleteRow(row.item.token)">
            Delete
          </b-button>
          <b-button class="action-button" size="sm" @click="row.toggleDetails">
            {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-button>
        </template>
        <template v-slot:row-details="row">
          <b-card>
            <ul class="recordDetails">
              <li v-for="(value, key) in row.item" :key="key">
                <span v-if="key!='_showDetails'">
                  <span style="font-weight: bold;">{{ key }}: </span>
                  <span style="font-style: italic;">{{ value }}</span>
                </span>
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Loader from '../common/Loader.vue';


export default {
  name: 'UrlTable',
  props: {
    ready: Boolean,
  },
  data() {
    return {
      items: null,
      loadingData: false,
      fields: [
        { key: 'email', sortable: true },
        { key: 'token', sortable: true },
        { key: 'plan_id', sortable: true },
        { key: 'role_id', sortable: true },
        { key: 'created_at', sortable: true },
        { key: 'updated_at', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      token: '',
      email: '',
      roleId: '',
      planId: '',
      password: '',
    };
  },
  components: {
    Loader,
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async deleteRow(token) {
      try {
        this.loadingData = true;
        await this.axios.delete(`/api/users/${token}`);
        this.items = (await this.axios.get('/api/users')).data;
      } catch (e) {
        alert(`Cannot delete this user: ${e.response.data.message}`);
      }
      this.loadingData = false;
    },
    updateRow(token) {
      this.token = token;
    },
    async handleOk() {
      try {
        this.loadingData = true;
        const data = {
          email: this.email,
          password: this.password,
          role_id: this.roleId,
          planId: this.planId,
        };
        await this.axios.put(`/api/users/${this.token}`, data);
        this.items = (await this.axios.get('/api/users')).data;
      } catch (e) {
        alert(`Cannot update this user: ${e.response.data.message}`);
      }
      this.loadingData = false;
      this.email = null;
      this.password = null;
      this.roleId = null;
      this.planId = null;
    },
  },
  async mounted() {
    this.loadingData = true;
    this.items = (await this.axios.get('/api/users')).data;
    this.loadingData = false;
    this.totalRows = this.items.length;
  },
};
</script>

<style scoped lang="scss">
  .recordDetails {
    list-style: None;
    text-align: left;
  }
  .action-button{
    margin: 0.2rem;
  }
</style>
