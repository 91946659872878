<template>
  <div>
    <alert ref="alert"></alert>
    <b-modal
      id="modal-center"
      centered title="Please provide an e-mail"
      @ok="handleOk"
    >
      <p class="my-4">
        We need it to collect analytics for you. E-mail is necessary if you do not want to lose access to analytics<br>
        <input
          id="emailInput"
          v-model="email"
          type="text"
          placeholder="e-mail">
      </p>
      <p class="my-4">
        If you do not want to login with access token you can also provide password.<br>
        <input
          id="passwordInput"
          v-model="password"
          type="password"
          placeholder="password">
      </p>
    </b-modal>
  </div>
</template>

<script>
import Alert from './common/Alert.vue';
import { userDataLSService } from '../localStorage';

export default {
  name: 'EmailPopUp',
  data: () => ({
    token: userDataLSService.get().token,
    email: null,
    password: null,
    alertMessage: '',
    dismissSecs: 3,
  }),
  components: {
    Alert,
  },
  methods: {
    async handleOk(event) {
      event.preventDefault();
      const data = {
        email: this.email,
        password: this.password,
      };
      try {
        await this.axios.put(`/api/users/${this.token}`, data);
        userDataLSService.set({ token: this.token, email: data.email });
        this.$router.go();
      } catch (e) {
        this.alertMessage = e.response.data.message;
        this.$refs.alert.showAlert(this.alertMessage, this.dismissSecs);
        this.alertMessage = '';
      }
    },
  },
  mounted() {
    if (!userDataLSService.get().email) {
      this.$bvModal.show('modal-center');
    }
  },
};
</script>

<style scoped lang="scss">
  @import "../assets/master";

  input {
    height: 2.7rem;
    padding: 0.8rem 1.2rem;
    font-size: 1rem;
    line-height: 1.1rem;
    border-radius: 0.3rem;
    border: 1px solid $main-color;
    transition-duration: 150ms;
    width: 100%;
    &:focus {
      box-shadow: inset 0.2em 0.2em $accent-color, inset -0.2em -0.2em $accent-color;
      border: 1px solid $main-color;
      outline: None;
    }
  }

  p {
    margin: 1rem;
    font-size: 1rem;
  }
</style>
