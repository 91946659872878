<template>
  <mdb-container>
    <mdb-line-chart
      :data="parsedChartData.lineChartData"
      :options="parsedChartData.lineChartOptions"
      :height="100"
    ></mdb-line-chart>
  </mdb-container>
</template>

<script>
import { mdbLineChart, mdbContainer } from 'mdbvue';

export default {
  name: 'ChartPage',
  components: {
    mdbLineChart,
    mdbContainer,
  },
  props: ['chartData'],
  computed: {
    parsedChartData() {
      return {
        lineChartData: {
          labels: this.chartData[0],
          datasets: [
            {
              label: null,
              backgroundColor: 'rgba(238, 238, 0, 0.2)',
              borderColor: 'rgba(150, 150, 0, 1)',
              borderWidth: 0.7,
              data: [...this.chartData[1], 1],
            },
          ],
        },
        lineChartOptions: {
          legend: {
            display: false,
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 10,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                display: false,
              },
            ],
          },
        },
      };
    },
  },
};
</script>
