<template>
  <div>
    <alert ref="alert"></alert>
    <form class="inputs">
      <div class="form-group">
        <input
          v-model="email"
          type="text"
          class="form-control"
          placeholder="e-mail">
      </div>
      <div class="form-group">
        <input
          v-model="password"
          type="password"
          class="form-control"
          placeholder="password">
      </div>
      <div class="form-group">
        <input
          v-model="passConf"
          type="password"
          class="form-control"
          placeholder="confirm password">
      </div>
      <div>
        <b-button
          class="functional-button"
          @click="register()"
        >Create Account</b-button>
        <p><a @click="$emit('register')">Back to login</a></p>
      </div>
    </form>
  </div>
</template>

<script>
import Alert from './common/Alert.vue';
import { userDataLSService } from '../localStorage';

export default {
  name: 'SignUp',
  data() {
    return {
      email: '',
      password: '',
      passConf: '',
      alertMessage: '',
      dismissSecs: 0,
    };
  },
  components: {
    Alert,
  },
  methods: {
    async register() {
      if (this.password === this.passConf) {
        const data = {
          email: this.email,
          password: this.password,
          token: userDataLSService.get().token,
        };
        try {
          const responseData = (await this.axios.post('/api/users', data)).data;
          if (responseData.token) {
            userDataLSService.set(responseData);
            this.$router.push('stats');
          }
        } catch (e) {
          this.dismissSecs = 3;
          if (e.response.data.message) {
            this.alertMessage = e.response.data.message;
            this.$refs.alert.showAlert(e.response.data.message, this.dismissSecs);
          } else {
            this.alertMessage = 'Make sure you entered data correctly';
            this.$refs.alert.showAlert(this.alertMessage, this.dismissSecs);
          }
        }
      } else {
        this.alertMessage = 'Passwords must match';
        this.dismissSecs = 3;
        this.$refs.alert.showAlert(this.alertMessage, this.dismissSecs);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../assets/master";

 .inputs{
    width: 90%;
    max-width: 600px;
    margin: 6rem auto 60px;
    .form-control, .functional-button {
      height: 3.4rem;
      padding: 1.1rem 1.4rem;
      font-size: 1.2rem;
      line-height: 1.2rem;
      border-radius: 0.3rem;
      border: 1px solid $main-color;
      transition-duration: 150ms;
    }
    .form-control {
      &:focus {
        box-shadow: inset 0.2em 0.2em $accent-color, inset -0.2em -0.2em $accent-color;
        border: 1px solid $main-color;
      }
    }
    div{
      margin-top: 30px;
    }
  }
  .functional-button{
    background-color: $main-color;
    color: white;
    width: 100%;
    &:focus{
      box-shadow: None;
      background-color: $main-color;
    }
    &:hover{
      background-color: $accent-color;
      color: $main-color;
    }
    &:active{
      box-shadow: None !important;
      background-color: $main-color !important;
    }
  }
  p {
    margin-top: 25px;
    color: $main-color;

    a {
      color: $main-color;
      transition-duration: 300ms;
      box-shadow: inset 0 0px 0 white, inset 0 -1px 0 $main-color;
      padding: 3px 10px;
      border-radius: 2px;

      &:hover {
        box-shadow: inset 0 0px 0 white, inset 0 -3px 0 $main-color,
        inset 0 0px 0 white, inset 0 -30px 0 $accent-color;
        cursor: pointer;
        text-decoration: None;
        color: $main-color;
      }
    }
  }
</style>
