<template>
  <mdb-container>
    <mdb-doughnut-chart
      :data="parsedData.doughnutChartData"
      :options="parsedData.doughnutChartOptions"
    ></mdb-doughnut-chart>
  </mdb-container>
</template>

<script>
import { mdbDoughnutChart, mdbContainer } from 'mdbvue';

export default {
  name: 'DonutChart',
  components: {
    mdbDoughnutChart,
    mdbContainer,
  },
  props: ['data', 'title'],
  computed: {
    parsedData() {
      return {
        doughnutChartData: {
          labels: Object.keys(this.data),
          datasets: [
            {
              backgroundColor: [
                '#F7464A',
                '#46BFBD',
                '#FDB45C',
                '#949FB1',
                '#4D5360',
              ],
              hoverBackgroundColor: [
                '#FF5A5E',
                '#5AD3D1',
                '#FFC870',
                '#A8B3C5',
                '#616774',
              ],
              data: Object.values(this.data),
            },
          ],
        },
        doughnutChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            fontSize: 30,
            text: this.title,
          },
        },
      };
    },
  },
};
</script>
