import axios from 'axios';
import VueAxios from 'vue-axios';
import Vue from 'vue';
import {
  BootstrapVue, BootstrapVueIcons, BIconBack, BIconArrowUpCircle,
} from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.min.css';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import { ApiEndpoint } from '../config';

Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueAxios, axios);
Vue.component(BIconBack);
Vue.component(BIconArrowUpCircle);

Vue.axios.defaults.baseURL = ApiEndpoint;

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
