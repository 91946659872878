<template>
    <b-alert
      class="error-alert"
      variant="danger"
      :show="dismissCountDown"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
      fade
    ><p>{{alertMessage}}</p></b-alert>
</template>

<script>
export default {
  name: 'Alert',
  data: () => ({
    dismissCountDown: 0,
    dismissSecs: 0,
    alertMessage: '',
  }),
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(message, time) {
      this.alertMessage = message;
      this.dismissSecs = time;
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>

<style scoped lang="scss">
  .error-alert{
    z-index: 10000;
    width: 90%;
    max-width: 600px;
    height: 4.8rem;
    margin: 0 auto;
    position: fixed;
    bottom: 5%;
    left: 0;
    right: 0;
    border: 1px solid;
    p{
      font-size: 1.2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
</style>
