<template>
  <div class="loader"></div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style scoped lang="scss">
@import "../../assets/master";

.loader {
  margin: 10px auto 0;
  border: 7px solid $accent-color;
  border-top: 7px solid $main-color;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1000ms cubic-bezier(.4, .1, .6, .9) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}
</style>
