<!-- When mapData contains only one country fill color of this country is incorrect -->

<template>
  <MapChart
  :countryData="{...mapData}"
  style="width: 100%"
  highColor="#ee0"
  lowColor="#eea"
  countryStrokeColor="#444"
  defaultCountryFillColor="#eee"
  />
</template>

<script>
import MapChart from 'vue-map-chart';

export default {
  name: 'MyMap',
  props: ['mapData'],
  components: {
    MapChart,
  },
};
</script>

<style scoped>

</style>
