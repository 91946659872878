import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Stats from '../views/Stats.vue';
import Login from '../views/Login.vue';
import Admin from '../views/Admin.vue';

import { userDataLSService } from '../localStorage';

Vue.use(VueRouter);

const routes = [
  {
    path: '/user/stats',
    name: 'Stats',
    component: Stats,
    beforeEnter: (to, from, next) => {
      if (userDataLSService.get().token) {
        next();
      } else {
        next(Home);
      }
    },
  },
  {
    path: '/user/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    beforeEnter: async (to, from, next) => {
      if (userDataLSService.get().email) {
        const data = {
          email: userDataLSService.get().email,
          token: userDataLSService.get().token,
        };
        const responseData = (await Vue.axios.post('/api/auth', data)).data;
        if (responseData.role_id === 2) {
          next();
        } else {
          next(Home);
        }
      } else {
        next(Home);
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
