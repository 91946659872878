<template>
  <button>{{val}}</button>
</template>

<script>
export default {
  name: 'NavigationButton',
  props: {
    val: String,
  },
};
</script>

<style scoped lang="scss">
  @import "../../assets/master";

  button {
    font-weight: bold;
    color: $main-color;
    padding: 12px 27px;
    margin: 0.5rem;
    background-color: $accent-color;
    border-radius: 0.3rem;
    transition-duration: 300ms;
    border: None;
    &:hover{
      transform: translate(-3px, -3px);
      box-shadow: 6px 6px $main-color;
    }
    &:active{
      color: white;
      background-color: $main-color;
    }
  }
</style>
