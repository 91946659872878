<template>
  <div>
    <b-navbar class="nav">
      <div class="col-sm"></div>
      <h1 @click="requestFinished=false">LINKTO NINJA</h1>
      <div class="col-sm text-right">
        <navigation-button
          v-if="!ifLogged"
          val="Log In"
          @click.native="$router.push({name: 'Login'})"
        ></navigation-button>
        <div v-else>
          <navigation-button
            v-if="ifAdmin"
            val="Admin panel"
            @click.native="$router.push({name: 'Admin'})"
          ></navigation-button>
          <navigation-button
            val="Analytics"
            @click.native="$router.push({name: 'Stats'})"
          ></navigation-button>
          <navigation-button
            val="Log Out"
            @click.native="logout"
          ></navigation-button>
        </div>
      </div>
    </b-navbar>
    <img alt="Logo" src="../assets/logo.png">
    <loader v-if="waitingForResponse"></loader>
    <div v-else>
      <div v-if="!requestFinished" class="short-url">
        <short-url @shorten-url="shortenUrl"></short-url>
      </div>
      <div v-else>
        <analytics
          :shorted-url="shortedUrl"
          :access-token="token"
        ></analytics>
      </div>
    </div>
  </div>
</template>

<script>
import Analytics from '../components/Analytics.vue';
import ShortUrl from '../components/ShortUrl.vue';
import NavigationButton from '../components/common/NavigationButton.vue';
import Loader from '../components/common/Loader.vue';
import { userDataLSService } from '../localStorage';
import { ShortenDomain } from '../../config';

export default {
  name: 'Home',
  components: {
    Analytics,
    ShortUrl,
    NavigationButton,
    Loader,
  },
  data: () => ({
    token: '',
    shortedUrl: '',
    requestFinished: false,
    waitingForResponse: false,
  }),
  computed: {
    ifLogged() {
      return !!userDataLSService.get().email;
    },
    ifAdmin() {
      return userDataLSService.get().role_id === 2;
    },
  },
  methods: {
    logout() {
      userDataLSService.remove();
      this.$router.go();
    },
    async shortenUrl(url) {
      this.waitingForResponse = true;
      const userToken = userDataLSService.get().token;
      let data = {};
      if (userToken) {
        data = {
          target_url: url,
          user_token: userToken,
        };
      } else {
        data = {
          target_url: url,
          user_token: null,
        };
      }
      const responseData = (await this.axios.post('/api/shorturls', data)).data;
      this.shortedUrl = `${ShortenDomain}/${responseData.short_url}`;
      userDataLSService.set({ ...userDataLSService.get(), token: responseData.user_token });
      this.token = userDataLSService.get().token;
      this.requestFinished = true;
      this.waitingForResponse = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/master";

img{
  margin: 0 auto;
}
.nav {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  h1 {
    font-size: 60px;
    text-shadow: 6px 6px $accent-color;
    &:hover{
      cursor: pointer;
    }
  }
}
</style>
