<template>
  <div class="analytics">
    <div class="server-response">
      <div class="description">
        <p>Shorted URL</p>
      </div>
      <div class="url-response">
        <p id="urlResponse">{{shortedUrl}}</p>
        <b-button
          @click="copyToClipboard('urlResponse')"
          class="functional-button copy-button"
        >Copy</b-button>
      </div>
    </div>
    <div class="go-to-analytics">
      <button
        @click="$router.push({name: 'Stats'})"
        class="functional-button analytics-button"
      >Go to analytics</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Analytics',
  props: {
    shortedUrl: String,
    accessToken: String,
  },
  methods: {
    copyToClipboard(containerid) {
      const range = document.createRange();
      range.selectNode(document.getElementById(containerid));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../assets/master";

  .server-response, .go-to-analytics{
    width: 90%;
    max-width: 600px;
    margin: 0 auto 60px;
    border: 1px solid $main-color;
    border-radius: 0.4rem;
    .url-response{
      padding-left: 20px;
      display: flex;
      justify-content: space-between;
    }
    .description{
      background-color: $main-color;
      color: white;
      padding: 10px;
      border-radius: 0.3rem 0.3rem 0 0;
    }
    p{
      margin: auto 0;
      width: 100%;
    }
  }
  .functional-button{
    border: 1px solid $main-color;
    background-color: $main-color;
    color: white;
    height: 3.4rem;
    padding: 1.1rem 1.4rem;
    font-size: 1.2rem;
    line-height: 1.2rem;
    border-radius: 0 0 0.3rem 0;
    transition-duration: 150ms;
    &:focus{
      box-shadow: None;
      background-color: $main-color;
    }
    &:hover{
      background-color: $accent-color;
      color: $main-color;
    }
    &:active{
      box-shadow: None !important;
      background-color: $main-color !important;
    }
  }
  .analytics-button{
    width: 100%;
    border-radius: 0.3rem;
  }
  .copy-button:focus{
    background-color: $accent-color;
    color: $main-color;
  }
</style>
