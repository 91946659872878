import SecureLS from 'secure-ls';
import { UserDataLSKey } from '../config';

const ls = new SecureLS();

class LocalStorageService {
  constructor(key) {
    this.key = key;
  }

  get = () => ls.get(this.key);

  set = (value) => ls.set(this.key, value);

  remove = () => ls.remove(this.key);
}

const userDataLSService = new LocalStorageService(UserDataLSKey);

export {
  LocalStorageService,
  userDataLSService,
};
