<template>
  <div>
    <email-popup></email-popup>
    <b-navbar class="nav">
      <div class="col-sm text-left">
        <router-link to="/" tag="h1">LINKTO NINJA</router-link>
      </div>
      <div class="col-sm text-right">
        <navigation-button
          v-if="ifAdmin"
          val="Admin panel"
          @click.native="$router.push({name: 'Admin'})"
        ></navigation-button>
        <navigation-button
          val="Home"
          @click.native="$router.push({name: 'Home'})"
        ></navigation-button>
        <navigation-button
          v-if="ifLogged"
          val="Log Out"
          @click.native="logout"
        ></navigation-button>
        <navigation-button
          v-else
          val="Log In"
          @click.native="$router.push({name: 'Login'})"
        ></navigation-button>
      </div>
    </b-navbar>
    <div class="container main">
      <div class="row container-content">
        <div class="url-list-wrapper">
          <div>
            <input class="form-control" v-model="search" type="text"
                   placeholder="find by: shorten link" aria-label="Search"/>
          </div>
          <b-form-select
            v-model="groupByParam"
            :options="groupByOptions"
            class="form-control"
            placeholder="Group by parameter"
          />
          <!--          <b-form-select-->
          <!--            v-model="filterValue"-->
          <!--            :options="filterOptions"-->
          <!--            class="form-control mb-3"-->
          <!--            :disabled="groupByParam === 'domain'"-->
          <!--            :placeholder="groupByParam === 'domain' ? 'Not available for domain' : 'Filter value'"-->
          <!--          />-->
          <b-list-group class="bar">
            <b-list-group-item v-for="(website, key) in computedWebsites" :key="key">
              <div
                class="site-name"
                :class="{'is-active': selectedLink === key}"
                @click.stop="handleUrlClick('site', key)"
              >
                <p><strong>{{ key.split('?')[0] || '(No Value)' }}</strong></p>
                <p class="site-icon-overall-clicks">{{ website.total_clicks }}</p>
              </div>
              <ul>
                <li
                  v-for="[urlKey, value] in Object.entries(website.urls)"
                  :key="urlKey"
                  :class="{'is-active': selectedLink === urlKey}"
                  @click.stop="handleUrlClick('url', urlKey, value.id, value.qr_code)"
                  @mouseover="selectedQr = value.qr_code"
                >
                  <span>{{ value.title || value.target_url.split('?')[0] }}</span>
                  <b-icon
                    icon="upc-scan"
                    class="icon"
                    v-b-modal.qr-modal
                    @click.stop
                  />
                  <b-icon
                    icon="back"
                    v-b-tooltip.click
                    class="icon"
                    triggers="click blur"
                    :title="`copied ${urlFromKey(urlKey)}`"
                    @click.stop="handleCopyUrl(urlFromKey(urlKey))"
                  />
                </li>
              </ul>
            </b-list-group-item>
          </b-list-group>
          <b-modal id="qr-modal" data-backdrop="static">
            <b-row>
              <b-img
                :src="selectedQr"
                :alt="selectedQr"
                class="w-100 mx-auto"
                fluid/>
            </b-row>
            <b-row>
              <b-col offset="3" cols="2">
                <b-button
                  v-b-tooltip.click
                  @click="handleCopyUrl(selectedQr)"
                  class="shorten-url-button"
                  :title="`copied ${selectedQr}`"
                  style="border-radius: 0 0.3rem 0.3rem 0"
                  variant="primary"
                  size="md"
                >Copy
                </b-button>
              </b-col>
              <b-col offset="1" cols="2">
                <b-button
                  @click="downloadImage(selectedLink, selectedQr)"
                  class="shorten-url-button"
                  style="border-radius: 0 0.3rem 0.3rem 0"
                  variant="primary"
                  size="md"
                >Download
                </b-button>
              </b-col>
            </b-row>
            <template #modal-footer/>
          </b-modal>
        </div>
        <div class="stats-container">
          <div class="loader-wrapper" v-if="loadingData">
            <loader></loader>
          </div>
          <div class="stats-content">
            <div class="row overall-cont">
              <h2 class="overall-clicks">{{ statsData ? statsData.totalClicks : 0 }}</h2>
              <h3 class="overall-desc">Overall clicks</h3>
            </div>
            <div class="row line-charts">
              <div class="period-stats last24h">
                <div class="period-stats__desc">
                  <h2>{{ statsData ? statsData.last24hoursClicks : 0 }}</h2>
                  <h3>Clicks in last 24 hours</h3>
                </div>
                <div class="period-stats__chart">
                  <line-chart
                    :chart-data="dailyLineChartData"
                  ></line-chart>
                </div>
              </div>
              <div class="period-stats last30d">
                <div class="period-stats__desc">
                  <h2>{{ statsData ? statsData.last30dayClicks : 0 }}</h2>
                  <h3>Clicks in last 30 days</h3>
                </div>
                <div class="period-stats__chart">
                  <line-chart
                    :chart-data="monthlyLineChartData"
                  ></line-chart>
                </div>
              </div>
            </div>
            <div class="row distinct-amounts">
              <div>
                <h2>{{ statsData ? statsData.details.qrReads : 0 }}</h2>
                <h3>QR code</h3>
              </div>
              <div>
                <h2>{{ statsData ? statsData.details.qrReadsDistinct : 0 }}</h2>
                <h3>Distinct QR code</h3>
              </div>
            </div>
            <div class="row distinct-amounts">
              <div>
                <h2>{{ statsData ? Object.keys(statsData.details.ipClicks).length : 0 }}</h2>
                <h3>Distinct users</h3>
              </div>
              <div>
                <h2>{{ statsData ? Object.keys(statsData.details.cities).length : 0 }}</h2>
                <h3>Distinct cities</h3>
              </div>
              <div>
                <h2>{{ statsData ? Object.keys(statsData.details.countryCodes).length : 0 }}</h2>
                <h3>Distinct countries</h3>
              </div>
            </div>
            <div class="row my-map-chart">
              <my-map :mapData="statsData ? statsData.details.countryCodes : {}"></my-map>
            </div>
            <div class="row isp-table">
              <h3>Isp ranking</h3>
              <b-table
                :items="statsData ? statsData.details.ispRanking : []"
                :fields="fields"
              >
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>
              </b-table>
            </div>
            <div class="row">
              <donut-chart :data="statsData ? statsData.details.referees : {}" title="Referees"/>
            </div>
            <div class="row">
              <donut-chart :data="statsData ? statsData.details.urlParams : {}" title="URL params"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';

import EmailPopup from '../components/EmailPopup.vue';
import LineChart from '../components/charts/LineChart.vue';
import DonutChart from '../components/charts/DonutChart.vue';
import MyMap from '../components/charts/MyMap.vue';
import NavigationButton from '../components/common/NavigationButton.vue';
import Loader from '../components/common/Loader.vue';

import {userDataLSService} from '../localStorage';
import {ShortenDomain} from '../../config';

export default {
  name: 'Stats',
  data: () => ({
    timezone: moment.tz.guess(),
    shortenDomain: ShortenDomain,
    overallClickStats: [],
    loadingData: false,
    websites: null,
    search: '',
    statsData: null,
    selectedLink: null,
    selectedQr: null,
    fields: ['index', 'name', 'clicks'],
    groupByParam: 'domain',
    filterValue: '',
    groupByOptions: ['domain'],
    filterOptions: [],
    autocompleteData: {},
  }),
  created() {
    this.getData();
  },
  components: {
    NavigationButton,
    EmailPopup,
    DonutChart,
    LineChart,
    Loader,
    MyMap,
  },
  computed: {
    ifLogged() {
      return !!userDataLSService.get().role_id;
    },
    ifAdmin() {
      return userDataLSService.get().role_id === 2;
    },
    dailyLineChartData() {
      const dateArray = this.statsData ? [...this.statsData.hourlyStatsLast24h] : [];
      if (dateArray && dateArray.length) {
        dateArray[0] = dateArray[0].map((val) => moment(val).tz(this.timezone).format('DD MMM HH:00'));
        return dateArray;
      }
      return [[], []];
    },
    monthlyLineChartData() {
      const dateArray = this.statsData ? [...this.statsData.dailyStatsLast30d] : [];
      if (dateArray && dateArray.length) {
        dateArray[0] = dateArray[0].map((val) => moment(val).tz(this.timezone).format('DD MMM YYYY'));
        return dateArray;
      }
      return [[], []];
    },
    computedWebsites() {
      if (!this.websites) return {};

      if (this.search === '') {
        return this.websites;
      }

      const searchedWebsites = {};

      Object.keys(this.websites).forEach((key) => {
        const website = this.websites[key];
        const urls = website.urls;
        const filteredUrls = {};

        Object.entries(urls).forEach(([urlKey, urlValue]) => {
          if (urlKey.includes(this.search)) {
            filteredUrls[urlKey] = urlValue;
          }
        });

        if (Object.keys(filteredUrls).length > 0) {
          searchedWebsites[key] = {...website, urls: filteredUrls};
        }
      });

      return searchedWebsites;
    }


  },
  methods: {
    async getData(key = null, link = null) {
      this.loadingData = true;
      let requestLink = '/api/stats';
      if (link) {
        requestLink += `?${key}=${link}`;
      } else {
        requestLink += '?show-all=true';
      }
      this.statsData = (await axios.get(requestLink, {
        headers: {
          Authorization: userDataLSService.get().token,
        },
      })).data;
      // eslint-disable-next-line no-return-assign
      setTimeout(() => this.loadingData = false, 300);
    },
    async handleUrlClick(type, key, link = null, qrCode = null) {
      const value = type === 'site' ? key : link;
      this.selectedLink = value === this.selectedLink ? '' : value;
      this.selectedQR = qrCode;
      if (this.selectedLink) await this.getData(type, value);
      else await this.getData();
    },
    async handleCopyUrl(url) {
      navigator.clipboard.writeText(url);
    },
    downloadImage(shortUrl, url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = `qr_code_${shortUrl}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    urlFromKey(key) {
      return `${this.shortenDomain.split('://')[1]}/${key}`;
    },
    logout() {
      userDataLSService.remove();
      this.$router.push({name: 'Home'});
    },
    async fetchDataWithGroupAndFilter() {
      let requestLink = `/user/shorturls/${userDataLSService.get().token}`;
      if (this.groupByParam && this.groupByParam !== 'domain') {
        requestLink += `?group_by=${this.groupByParam}`;
        if (this.filterValue) {
          requestLink += `&filter_value=${this.filterValue}`;
        }
      }
      this.websites = (await axios.get(requestLink, {
        headers: {
          Authorization: userDataLSService.get().token,
        },
      })).data;
    },
    async getAutocompleteOptions() {
      const response = await axios.get(`/api/shorturls/${userDataLSService.get().token}/autocomplete`, {
        headers: {
          Authorization: userDataLSService.get().token,
        },
      });
      this.autocompleteData = response.data;
      this.groupByOptions = ['domain', ...Object.keys(response.data)];
      this.updateFilterOptions();
    },
    updateFilterOptions() {
      if (this.groupByParam && this.groupByParam !== 'domain') {
        this.filterOptions = this.autocompleteData[this.groupByParam] || [];
      } else {
        this.filterOptions = [];
      }
      this.fetchDataWithGroupAndFilter();
    }
  },
  watch: {
    groupByParam() {
      this.updateFilterOptions();
    },
    filterValue() {
      if (this.groupByParam && this.groupByParam !== 'domain') {
        this.fetchDataWithGroupAndFilter();
      }
    },
  },
  async mounted() {
    await this.getAutocompleteOptions();
    this.websites = (await axios.get(`/user/shorturls/${userDataLSService.get().token}`)).data;
    this.getData();
  },
};
</script>

<style scoped lang="scss">
@import "../assets/master";

ul {
  list-style-type: none;
  margin-bottom: 0.3rem;
  padding: 0;
  text-align: left;

  li {
    transition-duration: 300ms;
    border-radius: 0.3rem;
    padding: 0.3rem 1rem;
    margin-left: 0.5rem;

    .icon {
      display: none;
      float: right;
      color: yellowgreen;
    }

    &:hover {
      background-color: $accent-color;
      cursor: pointer;

      .icon {
        display: inline-block;
      }
    }

    &:active {
      background-color: $main-color;
      color: white;

      .icon {
        display: inline-block;
      }
    }
  }
;
}

.is-active {
  background-color: $accent-color;
}

h1:hover {
  cursor: pointer;
}

.main {
  height: calc(100vh - 140px - 1rem);
  width: 100%;
  display: flex;
  margin: 1rem auto 0;

  .container-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 30px;

    .url-list-wrapper {
      flex: 1;
    }

    .stats-container {
      flex: 3;
    }

    .row {
      margin: 0.5rem 2rem 3rem;
    }

    .url-list-wrapper {
      max-width: 25%;
      word-wrap: break-word;
      @media only screen and (max-width: 960px) {
        max-width: unset;
        margin-bottom: 85px;
      }
      display: flex;
      flex-direction: column;

      .bar {
        border: 2px solid $main-color;
        box-shadow: 6px 6px $accent-color;
        margin-top: 15px;
        font-size: 20px;
        overflow: scroll;
        max-height: calc(100% - 38px);

        .list-group-item {
          border-left: 0;
          border-right: 0;

          &:first-of-type {
            border-top: 0;
          }

          &:last-of-type {
            border-bottom: 0;
          }
        }
      }
    }

    .stats-container {
      position: relative;
      height: 100%;
      padding: 0 3rem;

      .loader-wrapper {
        position: absolute;
        background-color: white;
        width: 100%;
        height: 100%;
        z-index: 3;
      }

      .stats-content {
        overflow: scroll;
        height: 100%;

        .card-container {
          display: flex;
        }
      }
    }
  }
}

.form-control {
  height: 3rem;
  padding: 0.5rem 0.5rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
  border-radius: 0.3rem;
  border: 2px solid $main-color;
  transition-duration: 150ms;
  box-shadow: 6px 6px $accent-color,
  inset 0 0 $accent-color,
  inset 0 0 $accent-color;
  margin-bottom: 10px;

  &:focus {
    box-shadow: 0 0 $accent-color,
    inset 0.2em 0.2em $accent-color,
    inset -0.2em -0.2em $accent-color;
    border: 2px solid $main-color;
  }
}

.site-name {
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
  transition-duration: 300ms;
  margin: auto 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 700;

  p {
    margin: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: $main-color;
    color: white;
  }

  .site-icon-overall-clicks {

  }
}

.line-charts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .container {
    padding: 0;
  }
}

.period-stats {
  border: 2px solid $main-color;
  margin: 1rem 0;
  width: 47%;
  border-radius: 0.3rem;
  box-shadow: 6px 6px $accent-color;
}

.period-stats__desc {
  padding-top: 1rem;
}

.distinct-amounts {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 1rem;
  padding: 2rem;
  border: 2px solid $main-color;
  border-radius: 0.3rem;
  box-shadow: 6px 6px $accent-color;

  div {
    margin: 0.3rem 1rem;
    flex: 1;
  }
}

.isp-table {
  border-radius: 0.3rem;
  padding: 1rem;
  border: 2px solid $main-color;
  box-shadow: 6px 6px $accent-color;

  h3 {
    margin: 0 auto 1rem;
  }
}

.overall-cont {
  display: flex;
  flex-direction: column;
  border-radius: 0.3rem;
  border: 2px solid $main-color;
  box-shadow: 6px 6px $accent-color;
  padding: 2rem;
}

/*
  div {
    background-color: #eef;
    border-radius: 0.3rem;
    animation: wixa 2000ms linear infinite;
  }

  @keyframes wixa {
    0% {
      filter: hue-rotate(0deg);
      box-shadow: 6px 6px $accent-color;
    }
    25% {
      filter: hue-rotate(90deg);
      box-shadow: 6px -6px $accent-color;
    }
    50% {
      filter: hue-rotate(180deg);
      box-shadow: -6px -6px $accent-color;
    }
    75% {
      filter: hue-rotate(270deg);
      box-shadow: -6px 6px $accent-color;
    }
    100% {
      filter: hue-rotate(360deg);
      box-shadow: 6px 6px $accent-color;
    }
  }
*/
h3 {
  font-weight: 100;
}

.nav {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  h1 {
    font-size: 60px;
    text-shadow: 6px 6px $accent-color;

    &:hover {
      cursor: pointer;
    }
  }
}

.container-content {
  margin: 0 auto !important;
}

@media only screen and (max-width: 960px) {
  .container, .main {
    margin: auto !important;
  }
  .period-stats {
    width: 100%;
  }
  .main {
    max-height: unset !important;
  }
  .row {
    margin-bottom: 1rem !important;
  }
  .stats-container {
    padding: 0 !important;
  }
  .my-map-chart {
    margin: 3rem 1rem !important;
  }
  .bar {
    overflow: unset !important;
    max-height: unset !important;
  }
  .stats-content {
    overflow: unset !important;
    max-height: unset !important;
  }
  .container-content {
    margin: 0 auto !important;
    flex-direction: column !important;
  }
}
</style>
